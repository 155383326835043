import {checkCookie, deleteCookie} from '../utils/cookies';

function getPostIdFromBody() {
    const {body} = document;

    const hasPostIdClass = Array.from(body.classList).some((className) => className.startsWith('postid-'));
    if (hasPostIdClass && body.classList.contains('is-paid-user')) {
        const postClass = Array.from(body.classList).find((cls) => /^postid-\d+$/.test(cls));

        if (postClass) {
            const postId = postClass.split('-')[1];
            return parseInt(postId, 10);
        }
    }

    return 0;
}

function toggleItem(item, shouldShow) {
    if (item) {
        item.classList.toggle('secondary-navigation__item--hidden', !shouldShow);
    }
}

/**
 * Es kann dazu kommen, dass User einen falschen Zustand der Navigation ausgespielt bekommen. Die Funktion überprüft die
 * Session und korrigiert diesen Zustand bei Bedarf.
 * @param userHasLoginAccess
 */
function maybeChangeNavbar(userHasLoginAccess) {
    const login = document.querySelector('.js-header-login');
    const profil = document.querySelector('.js-header-profile');
    const logout = document.querySelector('.js-header-logout');

    // Eingeloggte User sollen immer den Profil- und Logout-Link angezeigt bekommen, nicht aber den Login-Link.
    if (userHasLoginAccess) {
        toggleItem(login, false);
        toggleItem(profil, true);
        toggleItem(logout, true);

        return;
    }

    // Wenn der User keine gültige Session hat, sollen die Links entfernt werden und das `sso_session`-Cookie ebenfalls.
    toggleItem(login, true);
    toggleItem(profil, false);
    toggleItem(logout, false);

    deleteCookie('sso_session');
}

export default function verifyPlenigoUser() {
    const id = getPostIdFromBody();

    if (checkCookie('juve_login_session')) {
        fetch(`/wp-json/auth/v1/verify/?postId=${id}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'User-Agent': navigator.userAgent,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                maybeChangeNavbar(data.userHasLoginAccess ?? false);

                if (!data.needsReplacement) {
                    // eslint-disable-next-line no-new
                    const event = new CustomEvent('juvePlusError', {
                        detail: data,
                    });

                    document.dispatchEvent(event);
                }

                if (!data.hasAuthorisation && data.needsReplacement) {
                    deleteCookie('juve_plus');
                }
            })
            .catch((error) => {
                console.error('Fehler beim Abrufen der Session-Information:', error);
            });
    }
}
