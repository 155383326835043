export default function juvePlusErrorsEventListener() {
    document.addEventListener('juvePlusError', (event) => {
        if (event.detail && event.detail.needsReplacement && event.detail.content) {
            const articleContent = document.querySelector('.js-article-body');

            if (articleContent) {
                articleContent.innerText = '';

                const domParser = new DOMParser().parseFromString(event.detail.content, 'text/html');

                Array.prototype.forEach.call(domParser.body.childNodes, (node) => {
                    articleContent.appendChild(node);
                });
            }
        }
    });
}
